<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <n-table
      :total="total"
      :page-no="pageNo"
      :page-size="pageSize"
      :searched="searched"
      :searching="searching"
      :has-result="hasResult"
      @on-page-no="onPageNo"
      @on-page-size="onPageSize"
    >
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th>{{ $t('label.instanceId') }}</th>
            <th>{{ $t('label.executionId') }}</th>
            <th>{{ $t('label.startTime') }}</th>
            <th>{{ $t('label.endTime') }}</th>
            <th>{{ $t('label.status') }}</th>
            <th>{{ $t('label.exitCode') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.jobInstanceId | hyphen }}</td>
            <td>{{ row.jobExecutionId | hyphen }}</td>
            <td>{{ row.startTime | datetime }}</td>
            <td>{{ row.endTime | datetime }}</td>
            <td :class="['n-status-' + row.status.toLowerCase()]">{{ row.status }}</td>
            <td :class="['n-status-' + row.exitCode.toLowerCase()]">{{ row.exitCode }}</td>
            <td class="n-button-box">
              <n-button text="steps" custom-icon="fas fa-info-circle" @on-click="onBtnSteps(row)" />
              <nb-confirm text="remove" @on-ok="onBtnRemove(row)" />
              <nb-confirm text="restart" :disabled="row.status === 'COMPLETED'" @on-ok="onBtnRestart(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import SystemView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalHistorySteps from './ModalBatchHistorySteps';
import { getExecutions, removeExecution, restartExecution } from '@/api/system/system-batch';

export default SystemView.extend({
  name: 'SystemBatchHistory',
  mixins: [PaginateMixin],

  data() {
    return {
      breadcrumbItems: [],
    };
  },

  mounted() {
    const { params } = this.$route;
    this.breadcrumbItems = [params.name, this.$t(`label.history`)];
    this.search();
  },

  methods: {
    doSearch() {
      const jobName = this.$route.params.name;
      return getExecutions(jobName, this.pageNo, this.pageSize);
    },

    parse(res) {
      this.records = res.records;
      this.total = res.total;
    },

    onBtnRemove(row) {
      return removeExecution(row.jobInstanceId);
    },

    onBtnRestart(row) {
      return restartExecution(row.jobExecutionId);
    },

    onBtnSteps(row) {
      const props = { jobExecutionId: row.jobExecutionId };
      this.createModal(ModalHistorySteps, { props, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 1055px;

  th.rw-action {
    width: 14.5rem;
  }

  th.ro-action {
    width: 5rem;
  }
}
</style>
