<template>
  <n-modal v-bind="$props" :escapable="true" :custom-title="`Job Execution ID: ${jobExecutionId}`" :detail="true">
    <div class="p-scroll-container table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="step-id">{{ $t('label.stepId') }}</th>
            <th class="step-name">{{ $t('label.stepName') }}</th>
            <th class="n-table-timestamp">{{ $t('label.startTime') }}</th>
            <th class="n-table-timestamp">{{ $t('label.endTime') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th class="status">{{ $t('label.exitCode') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.stepExecutionId }}</td>
            <td>{{ row.stepName }}</td>
            <td>{{ row.startTime | datetime }}</td>
            <td>{{ row.endTime | datetime }}</td>
            <td :class="['n-status-' + row.status.toLowerCase()]">{{ row.status }}</td>
            <td :class="['n-status-' + row.exitCode.toLowerCase()]">{{ row.exitCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { getSteps } from '@/api/system/system-batch';

export default NModal.extend({
  name: 'ModalHistorySteps',

  components: { NModal },

  props: {
    jobExecutionId: [Number, String],
  },

  data() {
    return {
      loading: false,
      records: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.loading = true;
      this.records = [];
      getSteps(this.jobExecutionId)
        .then(res => {
          this.records = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.step-id {
  width: 80px;
}
.status {
  width: 100px;
}
.p-scroll-container {
  max-height: 500px;
  overflow-y: auto;
}
.n-table {
  min-width: 870px;
}
</style>
